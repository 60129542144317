import * as React from "react";
import {Button, Container, Grid, Header, Segment} from "semantic-ui-react";
import "../../css/intro.css";
import Hero from '../pages/hero01';
import "animate.css/animate.compat.css";
import {navigateTo} from "../../helpers/router";
import {t} from "../../i18n";
import { StaticImage } from 'gatsby-plugin-image';
import LandingSection01 from "../parts/LandingSection01";
import LandingBackgroundSection02 from '../backgrounds/landings/Section02';
import LandingBackgroundSection03 from '../backgrounds/landings/Section03';
import LandingBackgroundSection04 from "../backgrounds/landings/Section04";
import LandingBackgroundSection05 from '../backgrounds/landings/Section05';
import IntroListItem from "../utils/IntroListItem";
import NewFooter from './newfooter';
import SEO from "../seo";

export default class Landing01 extends React.Component {
  render() {
    return (
      <Segment basic className="overall-segment">
        <SEO title="Personalizowane suplementy diety dla mężczyzn"
          description="Zamów suplementy diety dla mężczyzn po 50, 40, 30,a także 20-latków,
            które dobierane są pod Twój wiek, wagę, wzrost i tryb życia. Niezbędne minerały i witaminy
            dla mężczyzn"
        />
        <Hero/>
        <LandingSection01>
          <Header as={"h2"} id='intro-h2'>Dobre suplementy <br /><b>dla mężczyzn świadomych</b></Header>
          <p id={'section-01-01-lead'}>Skończyłeś 20, 30, 40, a może 50 lat i jesteś w pełni świadom tego, że Twoje
            zapotrzebowanie na energię jest wyższe?</p>
          <p>Czy wiesz, że pewne minerały i witaminy dla
            mężczyzn rzeczywiście wspomagają Twój układ nerwowy i odpornościowy, sprawność,
            mięśnie, koncentrację i prostatę? Dbają o to, żebyś był zdrowy i w pełni sił? </p>
          <p>Zatem wysoko
            przyswajalne suplementy diety dla mężczyzn <b>Pure4me</b> mogą być tym, czego szukasz. Nie
            jest to pusta obietnica - od 25 lat zajmujemy się produkcją suplementów diety.</p>
        </LandingSection01>

        <LandingBackgroundSection02>
          <Segment basic id="section-01-02">
            <Container id="section-01-02-container">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={8} verticalAlign="middle">
                    <Header as={"h2"} id='intro-h3'>
                      Suplementy diety <b>dla mężczyzn</b> w wieku 50, 40 i 30 oraz 20 lat dobierane pod Twój <b>wiek, wagę, wzrost i tryb życia.</b>
                    </Header>
                    <p>Zapewne zastanawiasz się, jakie suplementy dla mężczyzn są dla Ciebie odpowiednie. Już nie musisz. Nasz zespół naukowy opracował specjalną ankietę, dzięki której poznamy Twój wiek, wagę, wzrost, dietę i tryb życia, i na
                      tej podstawie skomponujemy dla Ciebie Twój własny unikalny zestaw witamin i minerałów w ilościach, jakich potrzebuje Twój organizm. Nie będą to tzw. suplementy na testosteron, choć nasz mix wielowitaminowy z minerałami
                      zawiera też coś naturalnie wspomagającego i tę kwestię. Ma postać wygodnych saszetek do rozpuszczania w wodzie.</p>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <StaticImage src="../../img/landings/01/section_3.png" alt="Suplementy" placeholder="none"/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection02>

        <Segment basic style={{padding: "42px 0"}}>
          <Container>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={8}>
                  <StaticImage src="../../img/landings/01/section_4.png" alt="Suplementy" placeholder="none"/>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as={"h2"} id='intro-h3'><b>Kluczowe minerały i witaminy dla mężczyzn.</b> Bez sztucznych wypełniaczy, konserwantów czy cukru</Header>
                  <p>Nasze suplementy diety dla mężczyzn nie zawierają cukru, konserwantów ani żadnych sztucznych wypełniaczy. Są słodzone wartościową stewią i bezpiecznym nawet dla cukrzyków ksylitolem. Wiele z nich posiada opracowaną w
                    naszych laboratoriach aktywną metylowaną formę, która sprawia, że wykazują zwiększoną biodostępność. Pure4me, jako marka znanego producenta farmaceutycznego Natur Produkt Zdrovit, łączy w sobie innowacyjność, wiedzę
                    ekspertów i bezpieczeństwo. Posiadamy prestiżowe Certyfikaty Jakości Narodowego Instytutu Leków. Zobacz, jakie witaminy i suplementy dla mężczyzn po 50, 40 i 30 najczęściej pojawiają się w kompozycjach Pure4me.</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <LandingBackgroundSection03>
          <Segment basic id="section-01-03">
            <Container id="section-01-03-container">
              <Grid stackable relaxed={"very"} centered>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/01/ico_1.png' alt="Kompleks witamin grupy B" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Kompleks witamin z grupy B <b>na metabolizm i mózg</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Wraz z wiekiem funkcjonowanie Twojego organizmu zmienia się, jednak witamina B12, B6, ryboflawina, tiamina i kwas pantotenowy pomagają w prawidłowym funkcjonowaniu układu nerwowego. Co więcej, witamina
                          B6 wspomaga także zdrowy metabolizm białek - proces szczególnie ważny dla zachowania szczupłej sylwetki</p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/01/ico_2.png' alt="Potas i magnez" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Potas i magnez <b>dla sprawnych mięśni</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Te dwa niezbędne minerały dla mężczyzn odgrywają ogromną rolę w sprawnym funkcjonowaniu mięśni, również tego najważniejszego, czyli serca. Dodatkowo magnez jest dla Ciebie kluczowy, jeśli uprawiasz
                          sport oraz pijesz dużo kawy i herbaty. W dzień przyczynia się do zmniejszenia uczucia zmęczenia i znużenia. Warto tu wspomnieć, że nasze suplementy diety dla mężczyzn zawierają magnez w organicznej formie
                          cytrynianu.</p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/01/ico_3.png' alt="Cynk" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Cynk a <b>poziom testosteronu</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Cynk należy do ogromnie istotnych dla Ciebie minerałów. Oprócz tego, że uczestniczy w ponad 100 procesach biochemicznych w organizmie, w tym w prawidłowej syntezie białek,
                          to badania 1 pokazują, że jest on mocno związany ze zdrowiem seksualnym mężczyzn i prawidłowym poziomem testosteronu. Cynk to zatem jedyny w swoim rodzaju, mający wpływ
                          na poziom testosteronu suplement.</p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row/>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/01/ico_4.png' alt="Witaminy C i E" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>C i E - szczególnie ważne witaminy <b>dla mężczyzn po 40</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Zanieczyszczenia powietrza i światło słoneczne mają na nas nieustannie szkodliwy wpływ, co uwidacznia się szczególnie po ukończeniu 40 roku życia. Witaminy C i E to silne przeciwutleniacze, które
                          chronią Cię przed stresem oksydacyjnym i zwalczają wolne rodniki. Witamina C stymuluje też syntezę kolagenu, tak ważnego dla młodego wyglądu skóry i np. prawidłowego funkcjonowania naczyń krwionośnych.</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>


                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/01/ico_5.png' alt="Selen, jod i wapń" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Selen, jod i wapń <b>dla silnego, zdrowego ciała</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Selen to minerał, który chroni DNA białek i lipidów przed uszkodzeniem oksydacyjnym i przyczynia się do prawidłowego przebiegu spermatogenezy (czyli procesu powstawania oraz dojrzewania plemników). Jod wspomaga układ nerwowy oraz tarczycę i pomaga w utrzymaniu prawidłowych funkcji poznawczych, a wapń, którego zaczyna szczególnie brakować w miarę starzenia, utrzymuje kości zdrowymi i mocnymi.</p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection03>

        <LandingBackgroundSection04>
          <Segment basic id="section-01-04">
            <Container id="section-01-04-container">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Header as={'h2'} id='intro-h3'><b>Pure4me<br /> kompleksowe suplementy dla mężczyzn</b> w saszetkach zamiast miliona tabletek</Header>
                    <p>Zestaw saszetek Pure4me wystarcza na 30 dni i zawiera moc wysokiej jakości minerałów i witamin, które są najbardziej niezbędne dla Twojej witalności, sprawności fizycznej, a także umysłowej. Doskonale wiemy, że każdy
                      mężczyzna może potrzebować nieco innego składu, dlatego oferujemy rozmaite witaminy i suplementy dla mężczyzn.</p>
                    <p id={'section-01-01-lead'}>W zależności od tego, co wpiszesz w ankiecie, stworzony dla Ciebie zestaw może zawierać:</p>
                    <div>
                      <IntroListItem><b>kompleksowe minerały i witaminy</b> dla mężczyzn</IntroListItem>
                      <IntroListItem>suplementy diety <b>dla mężczyzn po 50</b></IntroListItem>
                      <IntroListItem>suplementy diety <b>dla mężczyzn po 40</b></IntroListItem>
                      <IntroListItem>suplementy <b>na włosy dla mężczyzn</b></IntroListItem>
                      <IntroListItem>a także personalizowane suplementy na <b>energię,
                          pamięć i koncentrację, odporność, zdrową wątrobę</b> lub <b>jelita</b></IntroListItem>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8} verticalAlign="middle">
                    <StaticImage src='../../img/landings/01/section_6.png' alt='Pozytywy suplementacji' placeholder='none' />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection04>

        <LandingBackgroundSection05>
          <Segment basic id="section-01-05">
            <Container id="section-01-05-container">
              <Grid stackable relaxed={'very'}>
                <Grid.Column width={8} verticalAlign="middle">
                  <StaticImage src='../../img/landings/section_7.png' alt='Suplementy' placeholder='none'/>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as={"h2"} id='intro-h2'><b>Zamów suplementy diety dla mężczyzn Pure4me</b></Header>
                  <p>Jeśli chcesz sprawdzić, jakie suplementy dla mężczyzn możemy Ci zaproponować, <b>wypełnij poniższą ankietę</b>. Jest bezpłatna i w żaden sposób nie zobowiązuje Cię do zakupu. Jej wypełnienie potrwa <b>około 5 minut</b>. Tyle czasu pewnie uda Ci się znaleźć, żeby <b>zadbać o swoją kondycję i sprawność</b>. Zwrotnie otrzymasz od nas propozycje suplementów, które następnie będziesz mógł nabyć przez stronę internetową <b>Pure4me</b>.</p>
                  <p><b>Zaczynając dbać o swoje zdrowie dziś, zapewniasz sobie sprawność na przyszłość.</b></p>
                  <Grid>
                    <Button primary className="btn-primary shadow-light newintro-primary-button" id="btn-check"
                            onClick={() => navigateTo('/survey')}>
                      {t("sprawdź swój skład")}!
                    </Button>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection05>

        <span id="footer">
          <NewFooter/>
        </span>
      </Segment>
    );
  }
}