import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BackgroundImg } from '../BackgroundImg';

const LandingBackgroundSection01 = ({children}) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "backgrounds/landings/new/landing_section_2_bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  return (
    <BackgroundImg image={data.desktop} className="background-bottom-center">
      {children}
    </BackgroundImg>
  )
}

export default LandingBackgroundSection01;
