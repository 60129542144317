import * as React from "react"
import FullApp from "../FullApp";
import Landing01 from "../components/pages/landing01";
import Layout from "../templates/layout";

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Landing01 />
    </Layout>
  </FullApp>
);