import * as React from "react";
import {Container, Grid, Segment} from "semantic-ui-react";
import "../../css/intro.css";
import { StaticImage } from 'gatsby-plugin-image';
import LandingBackgroundSection01 from "../backgrounds/landings/Section01";

const LandingSection01 = ({children}) => (
  <LandingBackgroundSection01>
    <Segment basic id="section-01-01">
      <Container id="section-01-01-container">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={8} verticalAlign="middle">
              <StaticImage src="../../img/landings/section_2.png" alt="Suplementy" placeholder="none" />
            </Grid.Column>
            <Grid.Column width={8} verticalAlign="middle">
              {children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </LandingBackgroundSection01>
);

export default LandingSection01;